import request from '@/utils/request'

// Load Settings Data
export function settingsdata() {
  return request({ url: 'vsettings', method: 'post', data: {x:'x'} })
} 


// Load Settings Data
export function getOcZone(country_id) {
  return request({ url: 'getoczone', method: 'post', data: {"country_id": country_id} })
} 


// Load Settings Data
export function save_profile(params) {
  return request({ url: 'saveprofilesettings', method: 'post', data: params})
} 


// Load Settings Data
export function apichangePassword(params) {
  return request({ url: 'changepassword', method: 'post', data: params})
}




export function generate_E_Cheque(params) {
  return request({ url: '/getimage/echequeimage', method: 'post', data: params})
}


export function toggleEChequeImage(params) {
  return request({ url: '/vtoggleechequeimage', method: 'post', data: params})
}

// Load Settings Data
export function getNigeriaLGAviaZone(zone_name) {
  return request({ url: 'vgetnigerialga', method: 'post', data: {"zone_name": zone_name} })
} 

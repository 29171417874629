
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {settingsdata, getOcZone, save_profile, apichangePassword, generate_E_Cheque, toggleEChequeImage, getNigeriaLGAviaZone}  from "@/api/settings";
import {getUserCountryCode} from "@/api/dashboard";
import * as Yup from "yup";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

import axios from 'axios';

import { getCurrentUser, setCurrentUser } from "@/utils/index"

export default defineComponent({
  name: "account-settings",
  components: {
    Field,
    Form,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  methods : {
    async loadSettingsData(){
      this.loading = true;
      const response = await settingsdata();
      this.cif = response.data.cif;
      this.cifex2 = response.data.cifex2;
      this.countries = response.data.countries;
      this.tinValue = response.data.tinValue;
      this.trueCode = response.data.trueCode;
      this.currentRank = response.data.currentRank;
      this.isHiddenNetworkActivity = response.data.isHiddenNetworkActivity;
      this.random1 = response.data.random1;
      this.selected_country = response.data.cifex2.country_id;

      this.zonename = response.data.zonename;
      if(this.selected_country != null){
        this.getRegionState(this.selected_country);

        if(this.selected_country == '156'){
          this.getNigeriaLGA(this.zonename);
        }
        
      }
      this.selected_zone = response.data.cifex2.zone_id;

      


      //this.selected_zone = response.data.cifex2.zone_id;
      this.avatarlink = response.data.avatarlink;
      this.fname = response.data.cif.fname;
      this.mname = response.data.cif.mname;
      this.lname = response.data.cif.lname;
      this.gender = response.data.cif.gender;
      this.birthday = response.data.cif.bday.substring(0,10);
      this.tin = response.data.cif.tin;
      this.address = response.data.cifex2.address;
      this.mobile = response.data.cifex2.mobileno;
      this.email = response.data.cifex2.email;
      this.fb = response.data.cifex2.fb;
      this.twitter = response.data.cifex2.twitter;
      this.job = response.data.cifex2.job;
      this.employer = response.data.cifex2.jobat;
      this.about = response.data.cifex2.about;
      this.showEChequeImage = response.data.showEChequeImage;

      this.selected_city = response.data.city;
      

      this.loading = false;

    },

    async getUserCcode(){
      const response = await getUserCountryCode();
      this.ccode = response.data.ccode;
    },
    async getNigeriaLGA(z){
      this.loadingLGA = true;
      const response = await getNigeriaLGAviaZone(z);

      if(response != undefined){
        this.cities = response.data.lgas;
        this.loadingLGA = false;
      }

      
    },
    async checkIfNigeria(e){ 

      if(this.selected_country == '156'){
        var zone = e.target.options[e.target.selectedIndex].text;
        this.getNigeriaLGA(zone);
      } 
    },
    
    async getRegionState(country_id){
      const response = await getOcZone(country_id);
      this.zones = response.data.zoneList;
    },
    async toggleHideNetworkActivity(){
      this.isHiddenNetworkActivity = !this.isHiddenNetworkActivity;
    },
    async changePassword(){
      var params = {
        "old" : this.oldpass,
        "new" : this.newpass,
        "confirm" : this.confirmpass
      }
      const response = await apichangePassword(params);
      if (response.data.result == "SUCCESS") {
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }); 
      } else if(response.data.result == "FAILED"){
        
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        }); 
      } 
    },
    
		handleFileUpload(){
			let  image = (this.$refs as any).avatar.files[0]; 
			this.uploadImage(image);
		},
    
		async uploadImage(image){
			 
			const formData = new FormData(); 
			formData.append('file', image); 
      
      
			const self = this;
			axios.post( process.env.VUE_APP_BASE_API + "uploadecavatar",
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
          'ECAPP-TWXHEADER': localStorage.getItem('ecmtctoken')
				}
			}
			).then(function(response){ 
          if(response.data !== undefined && response.data.success){
            self.avatarlink = response.data.url;
            let user = getCurrentUser();
            user.avatar = response.data.url;
             setCurrentUser(user);
             location.reload();
          }
			})
			.catch(function(){
			 
				 
			}); 
		},
    async saveProfileDetails(){
      var params = {
        "mobile" : this.mobile,
				"email" : this.email,
				"facebook" : this.fb,
				"twitter" : this.twitter,
				"job" : this.job,
				"employer" : this.employer,
				"about" : this.about,
				"address" : this.address,
				"tin" : this.tin,
				"gender" : this.gender,
				"country" : this.selected_country,
				"zone" : this.selected_zone,
        "hideOption" : this.isHiddenNetworkActivity,
        "city" : this.selected_city
      }
      
      const response = await save_profile(params);
       if (response.data.result == "SUCCESS") {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }); 
          return; 
        } else if(response.data.result == "FAILED"){
          
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          }); 
          return; 
        } 
    },
    async generateECheque(){
      this.isgeneratingCheque = true;
      var param = {
        "genType" : this.genType
      };
      const response = await generate_E_Cheque(param);
      this.isgeneratingCheque = false;
    },

    async showEChequeImageToggle(){
      this.showEChequeImage = !this.showEChequeImage;
      var param = {
        "showEChequeImage" : this.showEChequeImage
      };
      const response = await toggleEChequeImage(param);
      
    }
  },
  mounted(){
      //window.location.reload();
      this.loadSettingsData();
      this.getUserCcode();
      if(localStorage.getItem("pt") === 'ovi'){
          this.showGenerateECheque = true;
    }

    
    this.isBiboOnly = String(localStorage.getItem("bibo-only"));
  },
  data(){
      return { 
        cif : [],
        cifex2 : [],
        countries : [],
        zones : [],
        tinValue : "",
        trueCode : "",
        currentRank : "",
        random1: "",
        avatarlink: "",
        selected_country: "",
        selected_zone: "",

        selected_city: "0",

        fname:"",
        mname: "",
        lname: "",
        gender: "",
        birthday: "",
        tin: "",
        address: "",
        mobile: "",
        email: "",
        fb: "",
        twitter: "",
        job: "",
        employer: "",
        about: "",
        isHiddenNetworkActivity: false,

        oldpass: "",
        newpass: "",
        confirmpass: "",
        image: null,
        loading: true,
        genType: 0,
        showGenerateECheque : false,
        showEChequeImage : false,
        isgeneratingCheque: false,
        isBiboOnly : "false",
        ccode : -1,
        cities : [],
        loadingLGA : true,
        onload_city : "",
        zonename : ""
      }
  },
  setup() {
    const passwordFormDisplay = ref(false);
    const removeImage = () => {
      // profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", ["Settings"]);
      
    });

    return {
      // profileDetails,
      passwordFormDisplay,
      removeImage,
    };
  }
});
